<template>
  <div>
    <!-- pic52FD23492951567BA6C14FBF18EC1709.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/lu-newsletter-2.jpg">

    <p>
      Feast your eyes on the new
      <router-link :to="{ name: 'category', params: {id: 115301} }">
        LEGO Universe newsletter archive
      </router-link>!
    </p>
    <p>
      Surf through back issues to see some of the ways that imaginative fans like you have been involved in the game's development, such as inspiring the LEGO Universe logo and naming the LEGO Universe News Network! You'll also see other newsletter-only exclusives, like a sneak peek at Professor Brickkeeper's pet and behind the scenes interviews! And of course past newsletters include LEGO Universe VIP codes that you may not have discovered!
    </p>
    <p>
      Check out the archive anytime by using the new link on the left side of "News Network" web pages. Better yet, <router-link :to="{ name: 'newsletter' }">sign up</router-link> to receive future fun-packed newsletters in your email box for free! Newsletter subscribers get new VIP codes, inside scoops and other exclusives first!
    </p>
  </div>
</template>
